*{
  padding: 0%;
  margin: 0px;
  box-sizing: border-box;

}
.comming-soon{
  font-family: "Montserrat", sans-serif;
}
body {
  /* font-family: Arial, sans-serif; */
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0;
  /* background: linear-gradient(to right, #957DAD, #4A90E2); */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh !important;
  text-align: center;
}

.logo {
  font-size: 2em;
  color: white;
  margin-bottom: 20px;
}

h1 {
  /* color: white; */
  font-size: 3em;
  /* margin: 0px; */
}

p {
  /* color: white; */
  margin-bottom: 20px;
}

input[type="email"] {
  padding: 10px;
  border-radius: 5px;
  border: none;
  margin-right: 10px;
}

button {
  padding: 10px 15px;
  background-color: #4A90E2;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.social-icons {
  /* Add styling for social icons (if needed) */
}